import {
  DHCheckKind,
  DHCommentLevel,
  DHStatus,
  MachineType,
  PastillaName,
  PastillaStatus,
} from "#imports"

export const $icons = {
  versus: "i-ri-exchange-line",
  internal_external: "i-ri-global-line",
  matching: "i-fa6-solid-down-left-and-up-right-to-center",
  calendar: "i-fa6-solid-calendar-days",
  refresh: "i-fa6-solid-rotate-right",
  eye: "i-fa6-solid-eye",
  back_arrow: "i-fa6-solid-chevron-left",
  birthday: "i-ri-cake-2-fill",
  note: "i-ri-chat-new-line",
  help: "i-mdi-information-outline",
  edit: "i-heroicons-pencil",
  redo: "i-fa6-solid-arrow-rotate-left",
  warning: "i-fa6-solid-circle-exclamation",
  check: "i-fa6-solid-check",
  add: "i-fa6-solid-plus",
  back: "i-fa6-solid-arrow-left",
  forward: "i-fa6-solid-arrow-right",
  cross: "i-heroicons-x-mark-20-solid",
  phone: "i-fa6-solid-phone",
  model: {
    assetowner: "i-fa6-solid-user-group",
    assetmanager: "i-fa6-solid-users-gear",
    offtaker: "i-fa6-solid-building-user",
    holding: "i-fa6-solid-building-columns",
    contract: "i-fa6-solid-file-contract",
    site: "i-material-symbols-location-on-outline",
    portfolio: "i-fa6-solid-wallet",
    invoice: "i-fa6-solid-file-invoice-dollar",
    dso: "i-material-symbols-electric-bolt",
    tso: "i-material-symbols-auto-transmission",
    company: "i-fa6-solid-building",
  },
} as const

// __________ MachineType __________

const iconPrefix = "/images/google-markers"

export const mapTechnoGMapIcon: Record<MachineType, string> = {
  // wind
  [MachineType.enum.wind_turbine_onshore]: `${iconPrefix}/markers-google-wind-turbine.svg`,
  [MachineType.enum.wind_turbine_offshore]: `${iconPrefix}/markers-google-wind-offshore.svg`,
  // solar
  [MachineType.enum.solar_field_ground_mounted]: `${iconPrefix}/markers-google-solar-panel.svg`,
  [MachineType.enum.solar_field_rooftop]: `${iconPrefix}/markers-google-solar-roof-panel.svg`,
  [MachineType.enum.solar_field_canopy]: `${iconPrefix}/markers-google-solar-canopy-panel.svg`,
  // hydro
  [MachineType.enum.hydro_turbine_reservoir]: `${iconPrefix}/markers-google-dam.svg`,
  [MachineType.enum.hydro_turbine_run_of_river]: `${iconPrefix}/markers-google-hydro.svg`,
  [MachineType.enum.hydro_turbine_pumped_storage]: `${iconPrefix}/markers-google-hydro.svg`,
  // Coge
  [MachineType.enum.cogeneration_biomass]: `${iconPrefix}/markers-google-bio.svg`,
  [MachineType.enum.cogeneration_waste]: `${iconPrefix}/marker-incineration.svg`,
  [MachineType.enum.cogeneration_other]: `${iconPrefix}/marker-coge-other.svg`,
}

export const mapMachineTypeIconUrl: Record<MachineType, string> = {
  // wind
  [MachineType.enum.wind_turbine_onshore]: "i-rnx-wind-turbine-onshore",
  [MachineType.enum.wind_turbine_offshore]: "i-rnx-wind-turbine-offshore",
  // solar
  [MachineType.enum.solar_field_canopy]: "i-rnx-solar-canopy",
  [MachineType.enum.solar_field_rooftop]: "i-rnx-solar-rooftop",
  [MachineType.enum.solar_field_ground_mounted]: "i-rnx-solar-plain",
  // hydro
  [MachineType.enum.hydro_turbine_reservoir]: "i-rnx-hydro-dam",
  [MachineType.enum.hydro_turbine_run_of_river]: "i-rnx-hydro-default",
  [MachineType.enum.hydro_turbine_pumped_storage]: "i-rnx-hydro-default",
  // hydro
  [MachineType.enum.cogeneration_biomass]: "i-rnx-coge-bio",
  [MachineType.enum.cogeneration_waste]: "i-rnx-coge-incineration",
  [MachineType.enum.cogeneration_other]: "i-rnx-coge-other",
}

// __________ GoogleMap __________

export const gmapDisableUIExceptFullScreen = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: true,
  gestureHandling: "cooperative",
} as const

export const gmapDefaultCenter = { lat: 47.0844, lng: 2.3964 } as const // Bourges as a center ref

// __________ Pastilla __________

export const mapPastillaStatusIconColor: Record<PastillaStatus, string> = {
  [PastillaStatus.enum.error]: "text-red-600",
  [PastillaStatus.enum.good]: "text-green-600",
  [PastillaStatus.enum.warning]: "text-yellow-600",
  [PastillaStatus.enum.missing]: "text-gray-500",
  [PastillaStatus.enum.na]: "text-gray-500",
  [PastillaStatus.enum.skipped]: "text-gray-500",
  [PastillaStatus.enum.missing_configuration]: "text-yellow-600",
  [PastillaStatus.enum.compute_error]: "text-red-600",
}

export const mapPastillaNameIconName: Record<PastillaName, string> = {
  [PastillaName.enum.power_production]: "i-material-symbols-bolt-outline-rounded",
  [PastillaName.enum.availability]: "i-material-symbols-event-available",
  [PastillaName.enum.pr]: "i-ri-sun-foggy-line",
  [PastillaName.enum.data_health]: "i-mdi-medication-outline",
}

// __________ Invoice __________

export const mapInvoiceActionIcon = {
  compute: "i-fa6-solid-calculator",
  publish: "i-fa6-solid-file-circle-check",
  submit: "i-fa6-solid-share-from-square",
  //
  download_pdf: "i-fa6-solid-file-pdf",
  download_excel: "i-fa6-solid-file-excel",
  //
  [InvoiceCancelActions.enum.unpublish]: "i-fa6-solid-backward",
  [InvoiceCancelActions.enum.reject]: "i-fa6-solid-ban",
  [InvoiceCancelActions.enum.void_and_replace]: "i-fa6-solid-rotate-right",
  [InvoiceCancelActions.enum.credit_note]: "i-fa6-solid-square-minus",
  [InvoiceCancelActions.enum.delete]: "i-fa6-solid-trash",
  //
  submit_to_zero: "i-fa6-solid-bullseye",
  //
  set_as_submitted: "i-fa6-solid-envelope-circle-check",
  set_as_paid: "i-ri-money-euro-circle-line",
}

// __________ Notifications __________

export const mapNotifLevelIcon = {
  [NotifLevel.enum.alert]: {
    color: "red",
    icon: "i-fa6-solid-circle-xmark",
  },
  [NotifLevel.enum.warning]: {
    color: "yellow",
    icon: "i-fa6-solid-triangle-exclamation",
  },
  [NotifLevel.enum.info]: {
    color: "rnxblue",
    icon: "i-fa6-solid-circle-info",
  },
  [NotifLevel.enum.success]: {
    color: "rnxgreen",
    icon: "i-fa6-solid-circle-check",
  },
} as const

// __________ DataHealth __________

export const mapDHCheckToIcon: Record<DHCheckKind, string> = {
  [DHCheckKind.enum.config]: "i-material-symbols-settings-outline",
  [DHCheckKind.enum.freshness]: "i-material-symbols-alarm-outline",
  [DHCheckKind.enum.completeness]: "i-material-symbols-incomplete-circle",
  [DHCheckKind.enum.consistency]: $icons.matching,
}

export const mapDHStatusToColors: Record<DHStatus, string> = {
  [DHStatus.enum.good]: "rnxgreen",
  [DHStatus.enum.warning]: "yellow",
  [DHStatus.enum.critical]: "red",
  [DHStatus.enum.missing]: "gray",
}

export const mapCommentLevelTocolor: Record<DHCommentLevel, string> = {
  [DHCommentLevel.enum.error]: "red",
  [DHCommentLevel.enum.info]: "rnxblue",
  [DHCommentLevel.enum.aggregated]: "gray",
}
