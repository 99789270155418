import type { SeriesOptionsType } from "highcharts"
import Highcharts from "highcharts"
import * as R from "remeda"
import type { DataFrame, TimeSeries } from "#imports"

type SeriesOptionsData = Array<[Date | number, number | null]>
export type HCSeries = SeriesOptionsType & { data: SeriesOptionsData }

export function dfToTimeSeries(df: DataFrame, column: string): TimeSeries {
  if (!df.columns.includes(column)) {
    throw new Error(`Column ${column} was not found on given DataFrame.`)
  }

  const j = ld.findIndex(df.columns, (el: any) => el === column)
  const data: (number | null)[] = []
  for (let i = 0; i < df.index.length; i++) {
    data.push(df.data[i][j])
  }
  return { name: column, index: df.index, data }
}

export function dfArrayToTimeSeriesArray(df: DataFrame): TimeSeries[] {
  const arr = [] as TimeSeries[]
  for (let i = 0; i < df.columns.length; i++) {
    arr.push({
      name: df.columns[i],
      index: df.index,
      data: df.data.map((d) => d[i]),
    })
  }
  return arr
}

export function tsToSeriesOptionsData(
  values: TimeSeries,
  opts?: Partial<{
    coef: number
    cumulative: boolean
    indexTransform: (index: (number | Date)[]) => (number | Date)[]
  }>,
): SeriesOptionsData {
  const { cumulative, indexTransform, coef } = {
    coef: 1,
    cumulative: false,
    indexTransform: R.identity,
    ...opts,
  }

  const index = indexTransform(values.index)

  const series: SeriesOptionsData = []
  for (let i = 0; i < values.index.length; i++) {
    let value = R.isNumber(values.data[i]) ? values.data[i]! * coef : null
    if (cumulative) {
      const previous = values.data.slice(0, i).filter((v) => v !== null) as number[]
      const prevValue = previous.reduce((acc, current) => acc + current * coef, 0)
      if (R.isNumber(value)) {
        value = value + prevValue
      } else {
        value = prevValue
      }
    }

    series.push([index[i], value])
  }
  return series
}

export const highchart_french_translations = {
  contextButtonTitle: "Menu du contexte graphique",
  downloadCSV: "Télécharger le CSV",
  downloadJPEG: "Télécharger l'image JPEG",
  downloadPDF: "Télécharger le document PDF",
  downloadPNG: "Télécharger l'image PNG",
  downloadSVG: "Télécharger l'image vectorielle SVG",
  downloadXLS: "Télécharger XLS",
  exitFullscreen: "Sortir du plein écran",
  exportData: {
    annotationHeader: "Annotations",
    categoryDatetimeHeader: "DateHeure",
    categoryHeader: "Catégorie",
  },
  hideData: "Cacher la table de données",
  loading: "Chargement...",
  mainBreadcrumb: "Principal",
  noData: "Pas de données à afficher",
  printChart: "Imprimer le tableau",
  resetZoom: "Réinitialiser le zoom",
  resetZoomTitle: "Réinitialiser le niveau de zoom 1:1",
  shortMonths: [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Août",
    "Sept",
    "Oct",
    "Nov",
    "Déc",
  ],
  viewData: "Voir la table de données",
  viewFullscreen: "Voir en plein écran",
  zoomIn: "Zoom avant",
  zoomOut: "Zoom arrière",
  decimalPoint: ",",
  months: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  weekdays: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
  shortWeekdays: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
}

// because this is the default lang
export const highchart_english_translations = Highcharts.getOptions().lang
